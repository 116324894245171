import { useState, useEffect, useCallback, useRef, useMemo, useLayoutEffect } from "react";
import { useSearchParams } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "@/hooks/redux";
import { useGetPerformanceListQuery } from "@/services/apiQuery/livePerformance";
import { fetchMyStrategiesData, selectMyStrategies } from "@/store/strategies/my_strategies";
import {
  fetchMetricsList,
  getStrategyPerformanceResult,
  setActiveStrategy,
} from "@/store/strategies/strategies";
import { IStrategyData } from "@/types";

interface IParams {
  isEditMode?: boolean;
  strategyId?: string;
  isPreviewReady?: boolean;
}

export const useGetData = ({ isEditMode, strategyId }: IParams) => {
  const [searchParams] = useSearchParams();
  const [loadingData, setLoadingData] = useState(false);
  const [isEmpty, setIsEmpty] = useState<boolean | null>(null);
  const { list } = useAppSelector(selectMyStrategies);
  const [performanceLoadingStatus, setPerformanceLoadingStatus] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  const taskID = searchParams.get("task_id");
  const strategyName = searchParams.get("name");
  const hasPerformance = searchParams.get("has_performance");
  const originalStrategyName = useRef<string | null>(null);

  const { data: tasksList } = useGetPerformanceListQuery(null);

  const foundTaskConfig = useMemo(() => {
    if (taskID) return tasksList?.find((task) => task.task_id === taskID)?.meta.config || null;
  }, [taskID, tasksList]);

  const foundReadyTaskItem = useMemo(() => {
    if (taskID)
      return (
        tasksList?.find(
          (task) =>
            task.task_id === taskID &&
            task.seen === 0 &&
            (task.status === "SUCCESS" || task.status === "FAILURE")
        ) || null
      );
    return null;
  }, [taskID, tasksList]);

  const getDataForEdit = useCallback(async () => {
    if (Number.isInteger(Number(strategyId)) && strategyId) {
      await dispatch(fetchMetricsList());
      await dispatch(fetchMyStrategiesData()).then((data) => {
        const { payload } = data;
        const result = payload as IStrategyData[] | null;
        const foundStrategy = result?.find((item) => item.id === Number(strategyId));
        if (!foundStrategy) {
          setIsEmpty(true);
        } else {
          setIsEmpty(false);
        }
      });
      setLoadingData(false);
    } else {
      setIsEmpty(true);
    }
  }, [strategyId]);

  useEffect(() => {
    const getDataForCreate = () => {
      setIsEmpty(false);
    };

    if (!isEditMode) getDataForCreate();
    else {
      // return empty if in edit mode and [:id] not provided
      if (!Number.isInteger(Number(strategyId))) setIsEmpty(true);
      else {
        setLoadingData(true);
        getDataForEdit();
        setIsEmpty(null);
      }
    }
  }, [getDataForEdit, isEditMode]);

  const setCurrentStrategyForm = useCallback(() => {
    if (list && list.length > 0 && foundTaskConfig && strategyName) {
      const name = strategyName;
      const foundStrategy = list.find((strategy) => strategy.id === Number(strategyId));
      originalStrategyName.current = foundStrategy ? foundStrategy.name : "";
      const newConfig = { config: foundTaskConfig } as IStrategyData;

      dispatch(setActiveStrategy({ ...foundStrategy, ...newConfig, name }));
    }
  }, [list, foundTaskConfig, strategyName, strategyId]);

  const setDefaultStrategyForm = useCallback(() => {
    if (list && list.length > 0 && strategyId && Number.isInteger(Number(strategyId))) {
      const foundStrategy = list.find((strategy) => strategy.id === Number(strategyId));
      if (foundStrategy && foundStrategy.permissions?.has_write && strategyName) {
        originalStrategyName.current = foundStrategy.name;

        dispatch(setActiveStrategy({ ...foundStrategy, name: strategyName }));
      } else setIsEmpty(true);
    }
  }, [list, strategyId, strategyName]);

  // set current form data
  useEffect(() => {
    if (foundTaskConfig) {
      setCurrentStrategyForm();
    } else setDefaultStrategyForm();
  }, [setCurrentStrategyForm, setDefaultStrategyForm]);

  // get performance result if task is ready
  useLayoutEffect(() => {
    if (hasPerformance === "true" && foundReadyTaskItem && taskID) {
      setPerformanceLoadingStatus(true);
      dispatch(getStrategyPerformanceResult(taskID)).then(() => {
        setTimeout(() => setPerformanceLoadingStatus(false), 500);
      });
    }
  }, [hasPerformance, foundReadyTaskItem, taskID]);

  useLayoutEffect(() => {
    if (taskID && hasPerformance === "true" && !foundReadyTaskItem) {
      setPerformanceLoadingStatus(true);
      dispatch(getStrategyPerformanceResult(taskID)).then(() => {
        setTimeout(() => setPerformanceLoadingStatus(false), 500);
      });
    }
  }, [hasPerformance, taskID]);

  return {
    isEmpty: performanceLoadingStatus ? null : isEmpty,
    isLoadingData: isEditMode && loadingData,
    originalStrategyName: originalStrategyName.current,
  };
};
