import { useEffect, useMemo } from "react";

import { useAppDispatch } from "@/hooks";
import {
  invalidatePerformance,
  useGetPerformanceListQuery,
} from "@/services/apiQuery/livePerformance";
import { ILivePerformanceItem } from "@/types";

export interface ITaskInfo {
  strategies: string | null;
  portfolios: string | null;
  hasRunningItems: boolean;
  strategiesList: ILivePerformanceItem[] | null;
  portfoliosList: ILivePerformanceItem[] | null;
  hasUnreadItems: boolean;
}

let timerID: NodeJS.Timeout | null = null;
const UPDATE_TIMEOUT = 5_000; // 5 sec

export const usePerformanceLiveWatcher = ({ disabled = false }) => {
  const { data: list, isFetching } = useGetPerformanceListQuery(null, { skip: disabled });
  const dispatch = useAppDispatch();

  const itemsInfo = useMemo((): ITaskInfo | null => {
    if (list && list?.length > 0) {
      const strategies = list.filter(
        (s) => s.meta.type === "strategy" && s.seen === 0 && s.meta.name
      );
      const strategiesRunning = strategies.filter(
        (s) => s.status === "STARTED" || s.status === "PENDING"
      );
      const readyStrategies = strategies.filter(
        (s) => s.status === "SUCCESS" || s.status === "FAILURE"
      ).length;

      // const portfolios = list.filter(
      //   (p) => p.meta.type === "portfolio" && p.seen === 0 && p.meta.name
      // );
      const portfolios = [] as ILivePerformanceItem[];

      const portfoliosRunning = list.filter((p) => p.status === "STARTED");
      const readyPortfolios = portfolios.filter(
        (p) => p.status === "SUCCESS" || p.status === "FAILURE"
      ).length;

      const hasStrategies = Boolean(strategies.length);
      const hasPortfolios = Boolean(portfolios.length);

      const result = {
        strategies: hasStrategies ? `${readyStrategies}/${strategies.length}` : null,
        portfolios: hasPortfolios ? `${readyPortfolios}/${portfolios.length}` : null,
        hasRunningItems: Boolean(strategiesRunning.length || portfoliosRunning.length),
        strategiesList: strategies.length ? strategies : null,
        portfoliosList: portfolios.length ? portfolios : null,
        hasUnreadItems: Boolean(strategies.length || portfolios.length),
      };
      return result;
    }
    return null;
  }, [list]);

  useEffect(() => {
    // watch timer
    const watchTimer = (updateTimeout: number) => {
      timerID = setTimeout(() => {
        dispatch(invalidatePerformance());
        // restart itself
        watchTimer(updateTimeout);
      }, updateTimeout);
    };

    if (itemsInfo?.hasRunningItems) {
      watchTimer(UPDATE_TIMEOUT);
    } else if (timerID) {
      clearTimeout(timerID);
    }

    // cleanup after destroy component
    return () => {
      timerID && clearTimeout(timerID);
    };
  }, [itemsInfo?.hasRunningItems]);

  return {
    list,
    itemsInfo,
    isLoading: isFetching,
  };
};
