import Highcharts, { SeriesOptionsType } from "highcharts/highstock";
import HC_exporting_data from "highcharts/modules/export-data";
import HC_exporting from "highcharts/modules/exporting";
import HighchartsReact from "highcharts-react-official";
import { FC, useMemo } from "react";

import { createKeyDriversChartOptions } from "./utils";

HC_exporting(Highcharts);
HC_exporting_data(Highcharts);

export interface IKeyDriversChatSerieData {
  type: "bar" | "pie" | "spline" | "column";
  seriesData: SeriesOptionsType[];
  categories?: string[];
  inPercentage?: boolean | undefined;
}

interface LineChartProps {
  chartData: IKeyDriversChatSerieData;
  height?: number;
  type: "guidance" | null;
}
export const ChartKeyDrivers: FC<LineChartProps> = ({ chartData, height = 350, type }) => {
  const chartOptions = useMemo(() => createKeyDriversChartOptions(chartData, type), [chartData]);

  return (
    <div style={{ height: height ? height : "100%", display: "flex" }}>
      <HighchartsReact
        highcharts={Highcharts}
        options={chartOptions}
        containerProps={{ style: { height: "auto", width: "100%" } }}
      />
    </div>
  );
};
