import { ERRORS } from "@/constants";
import { IndicatorOperatorType } from "@/store/strategies/customIndicator";
import {
  FormErrorFieldsType,
  IConstructorForm,
  IInputField,
  INewStartegyForm,
  InputFieldNames,
} from "@/types";

import { prepareExitIndicatorFormula } from "./customIndicator";

const MIN_PASSWORD_LEN = 8;
const MAX_PASSWORD_LEN = 50;
const MAX_EMAIL_LEN = 100;
const MIN_EMAIL_LEN = 6;
const MIN_USER_NAME_LEN = 1;
const MIN_USER_LAST_NAME_LEN = 1;
const MAX_NAME_LEN = 50;
const MAX_STRATEGY_NAME_LEN = 80;
const MIN_STRATEGY_NAME_LEN = 3;
const MIN_PHONE_LEN = 9;
const MAX_PHONE_LEN = 16;
const MIN_MESSAGE_LEN = 6;
const MAX_MESSAGE_LEN = 1000;
const MAX_DESCRIPTION_LEN = 200;
const MAX_DEFAULT_LEN = 100;
const MIN_CUSTOM_INDICATOR_NAME = 3;

export const MAX_STOP_GAIN_VALUE = 1000;
export const MAX_STOP_LOSS_VALUE = -100;
export const MAX_PERCENTAGE_VALUE = 100;
export const MAX_RATIO_VALUE = 100;
export const MAX_INPUT_DESCRIPTION_LEN = 2000;

const emailRegExp = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
const nameRegExp = /[\w \-& ]+/;
const phoneRegExp = /\+[\d \- ]+/;
const customIndicatorRegExp = /^[a-zA-Z0-9]+$/g;

export const validateEmail = (value: string): string | null => {
  if (value && !emailRegExp.test(value)) return "Email not valid!";
  if (value && value.length < MIN_EMAIL_LEN) return "Email is too short!";
  if (value && value.length > MAX_EMAIL_LEN) return "Email is too long!";
  return null;
};

export const validateLogin = (value: string): string | null => {
  if (value && !emailRegExp.test(value)) return "User login or email not valid!";
  if (value && value.length < MIN_EMAIL_LEN) return "Login or email is too short!";
  if (value && value.length > MAX_EMAIL_LEN) return "Login  or email is too long!";
  return null;
};

export const validatePassword = (value: string): string | null => {
  if (value && value.includes(" ")) return "User password not valid!";
  if (value && value.length < MIN_PASSWORD_LEN) return "Password is too short!";
  if (value && value.length > MAX_PASSWORD_LEN) return "Password is too long!";
  return null;
};

export const validateName = (value: string): string | null => {
  const isMatch = value?.match(nameRegExp)?.[0] === value;
  if (value && !isMatch) return "User name not valid!";
  if (value && value.length < MIN_USER_NAME_LEN) return "User name is too short!";
  if (value && value.length > MAX_NAME_LEN) return "User name is too long!";
  return null;
};

export const validateLastName = (value: string): string | null => {
  const isMatch = value?.match(nameRegExp)?.[0] === value;
  if (value && !isMatch) return "User name not valid!";
  if (value && value.length < MIN_USER_LAST_NAME_LEN) return "User name is too short!";
  if (value && value.length > MAX_NAME_LEN) return "User name is too long!";
  return null;
};

export const validateStrategyName = (name: string | null): string | null => {
  const isMatch = name?.match(nameRegExp)?.[0] === name;
  if (name && !isMatch) return "Use only letters, numbers, space, comma, dash or underscore!";
  if (name && name.trim().length < MIN_STRATEGY_NAME_LEN)
    return "Strategy name too short! Please, type more symbols.";
  if (name && name.trim().length > MAX_STRATEGY_NAME_LEN) return "Strategy name too long!";
  return null;
};

export const validateCompany = (value: string | null): string | null => {
  const isMatch = value?.match(nameRegExp)?.[0] === value;
  if (value && !isMatch) return "Use only letters, numbers, space, comma, dash or underscore!";
  if (value && value.trim().length < MIN_STRATEGY_NAME_LEN)
    return "Company name too short! Please, type more symbols.";
  if (value && value.trim().length > MAX_NAME_LEN) return "Company name too long!";
  return null;
};

export const validatePhone = (value: string | null): string | null => {
  const isMatch = value?.match(phoneRegExp)?.[0] === value;
  if (value && !isMatch) return "Use only numbers, space, dash or plus!";
  if (value && value.trim().length < MIN_PHONE_LEN) return "Phone number too short!";
  if (value && value.trim().length > MAX_PHONE_LEN) return "Phone number too long!";
  return null;
};

export const validateMessage = (value: string | null): string | null => {
  if (value && value.trim().length < MIN_MESSAGE_LEN)
    return "A Message too short! Should be at least 6 characters.";
  if (value && value.trim().length > MAX_MESSAGE_LEN)
    return "A message too long! Should be less than 1000 characters.";
  return null;
};

export const validateIndicatorName = (value: string): string | null => {
  if (value && !value.match(customIndicatorRegExp))
    return "Indicator name should contain only letters and digits without break spaces.";
  if (value && value.length < MIN_CUSTOM_INDICATOR_NAME) return "Indicator name is too short!";
  if (value && value.length > MAX_NAME_LEN) return "Indicator name is too long!";
  return null;
};

export const validateMaxLen = (value: string | null): string | null => {
  if (value && value.trim().length > MAX_DEFAULT_LEN)
    return `Field value too long! Should be less than ${MAX_DEFAULT_LEN} characters.`;
  return null;
};

export const validateIndicatorDescription = (value: string): string | null => {
  if (value && value.length > MAX_DESCRIPTION_LEN) return "Indicator description is too long!";
  return null;
};

type List = Record<string, (value: string) => string | null>;
type KeyOfList = keyof List;

const validatorsList: List = {
  login: validateLogin,
  email: validateEmail,
  password: validatePassword,
  repassword: validatePassword,
  firstName: validateName,
  lastName: validateLastName,
  fullName: validateName,
  company: validateCompany,
  phone: validatePhone,
  message: validateMessage,

  location: validateMaxLen,
  aum: validateMaxLen,
  name: validateMaxLen,
  strategy: validateMaxLen,
  comment: validateMaxLen,
  type: validateMaxLen,
};

export const validate = (
  form: Record<KeyOfList, { value: string; error: string | null }>,
  key?: KeyOfList | [KeyOfList, KeyOfList]
): [Record<KeyOfList, { value: string; error: string | null }>, boolean] => {
  const keys = Object.keys(form) as KeyOfList[];
  let isValid = true;

  if (Array.isArray(key)) {
    isValid = form[key[0]].value === form[key[1]].value;
    if (!isValid) form[key[1]].error = "Password does not match!";
    return [{ ...form }, isValid];
  }

  const validated = keys.reduce<Record<KeyOfList, { value: string; error: string | null }>>(
    (acc, fieldKey) => {
      const validator = validatorsList[fieldKey] as ((value: string) => string | null) | undefined;
      if (validator && (key === fieldKey || key === undefined)) {
        const error = validator(form[fieldKey].value);

        if (error) isValid = false;

        acc[fieldKey] = {
          ...form[fieldKey],
          error,
        };
      } else {
        acc[fieldKey] = form[fieldKey];
      }

      return acc;
    },
    {}
  );

  return [validated, isValid];
};

export const validateFormula = (value: string | null) => {
  const brackets = value
    ?.split("")
    .reduce<string[]>((acc, item) => {
      if (item === ")" || item === "(" || item === "[" || item === "]") acc.push(item);
      return acc;
    }, [])
    .join("");

  const bracketsValidator = (bracketsStr: string): string | boolean => {
    const check = bracketsStr.replace(/\(\)|\[]/, "");
    if (check === bracketsStr) {
      return !bracketsStr;
    }
    return bracketsValidator(check);
  };
  const isValid = brackets ? bracketsValidator(brackets) : true;

  return isValid ? null : "Expression is not valid! Please, check the brackets sequence.";
};

export const validateOptionInputs = (input: Record<InputFieldNames, IInputField>) => {
  const validated = {
    ...Object.keys(input).reduce((acc, key) => {
      acc[key as InputFieldNames] = {
        value: input[key as InputFieldNames].value,
        error: input[key as InputFieldNames].error,
      };
      return acc;
    }, {} as Record<InputFieldNames, IInputField>),
  };

  Object.keys(input).forEach((key) => {
    const value = validated[key as InputFieldNames].value;
    if (
      key === "minDays" &&
      value &&
      (Number(value) < 1 || !Number.isInteger(Number(value)) || Number(value) > 3_650)
    )
      validated[key].error = "Invalid day number!";
    else if (
      key === "maxDays" &&
      value &&
      (Number(value) < 1 ||
        !Number.isInteger(Number(value)) ||
        Number(value) > 10_000 ||
        Number(input.maxDays.value) < Number(input.minDays.value))
    )
      validated[key].error = "Invalid day number!";
    else if (
      key === "stopGain" &&
      value &&
      (Number(value) < 0 || Number(value) > MAX_STOP_GAIN_VALUE)
    )
      validated[key].error = "Invalid gain number!";
    else if (
      key === "stopLoss" &&
      value &&
      (Number(value) > 0 || Number(value) < MAX_STOP_LOSS_VALUE)
    )
      validated[key].error = "Invalid gain number!";
    else if (
      key === "percentage" &&
      value &&
      (Number(value) < 0 || Number(value) > MAX_PERCENTAGE_VALUE)
    )
      validated[key].error = "Invalid percentile number!";
    else if (key === "ratio" && value && (Number(value) < 0 || Number(value) > MAX_RATIO_VALUE))
      validated[key].error = "Invalid rate number!";
    else validated[key as InputFieldNames].error = "";
  });

  return validated;
};

export const validateForm = (form: INewStartegyForm): boolean[] => {
  const isWeightSchemeSelected = !!form.tradingInstrument.equityBaskets.weightScheme || false;
  let result = [false, false];

  const checkFormName = () => {
    if (form.name) result = [true, true];
    else result = [false, true];
  };

  // for option conditions
  if (form.tradingInstrument.type === "options") {
    const { ticker, minDays, maxDays, stopGain, stopLoss, percentage, ratio, strikeRatioType } =
      form.tradingInstrument.options;

    if (minDays.error || maxDays.error || stopGain.error || stopLoss.error) return result;
    if (!minDays.value || !maxDays.value) return result;
    if (
      (strikeRatioType === "XX percentage ITM" || strikeRatioType === "XX percentage OTM") &&
      (percentage.error || !percentage.value)
    )
      return result;
    if (
      (strikeRatioType === "XX STDEV ITM" || strikeRatioType === "XX STDEV OTM") &&
      (ratio.error || !ratio.value)
    )
      return result;
    if (!ticker) return result;
  }
  // for basket conditions
  if (form.tradingInstrument.type === "baskets") {
    const {
      tradingInstrument: { equityBaskets },
    } = form;
    if (equityBaskets.basket === "custom-basket" && !equityBaskets.tickers?.length) return result;
    if (equityBaskets.basket === null) return result;
    if (equityBaskets.basket === "custom-basket" && equityBaskets.tickers.length === 0)
      return result;
    if (form.error) return result;
    if (!isWeightSchemeSelected) return result;

    checkFormName();

    return result;
  }

  // for other conditions
  if (!form.error) {
    if (form.tradingInstrument.type === "stocks/etfs" && !form.ticker.equities && !form.ticker.etfs)
      return result;
    if (form.tradingInstrument.type === "cryptos" && !form.ticker.cryptos) return result;
  }

  if (form.error) return result;

  checkFormName();

  return result;
};

export const validateCustomIndicator = (form: IConstructorForm): [boolean, FormErrorFieldsType] => {
  const error = {
    name: validateIndicatorName(form.name || ""),
    description: validateIndicatorDescription(form.description || ""),
  };
  return [!error.name && !error.description, error];
};

export const validateExitCustomFormula = (
  constructor: IndicatorOperatorType[] | null,
  isDisabled?: boolean
): [boolean, string[], string | null] => {
  if (isDisabled) return [true, [], null];

  const formula = prepareExitIndicatorFormula(constructor);
  const comparativeList = [">", "<", ">=", "<=", "==", "!="];

  if (formula.join("").includes("Ticker")) {
    return [false, formula, ERRORS.exitFormulaError];
  }

  if (formula.some((item) => comparativeList.some((compItem) => compItem === item)) === false) {
    return [false, formula, ERRORS.comparativeError];
  }

  return [true, formula, null];
};
