import dayjs from "dayjs";
import Highcharts, {
  ExportingOptions,
  Options,
  PointOptionsObject,
  SeriesBarOptions,
} from "highcharts/highstock";

import { CHART_COLORS, DATE_ISO_US, labels } from "@/constants";
import { IExpectedReturn } from "@/types";
import { IYoYData } from "@/types/forecast";

import { IKeyDriversChatSerieData } from "./ChartKeyDrivers";

export const FLOAT_DIGITS = 2;

const replaceFields: Record<string, { replace: string; visible: boolean }> = {
  "Weighted 10-Percentile": {
    replace: "Bottom 10-Percentile",
    visible: true,
  },
  "Weighted 90-Percentile": {
    replace: "Top 10-Percentile",
    visible: true,
  },
  "Weighted Median": {
    replace: "Median",
    visible: true,
  },
};

export const createForecastZone = (len: number, forecastKey: string | null) => {
  return forecastKey && len > 0
    ? {
        zoneAxis: "x",
        zones: [
          { value: len - 2, dashStyle: "Solid" },
          { dashStyle: "Dash", color: "var(--secondary)" },
        ],
      }
    : {};
};

// Highcharts ToolTip Formatter
export function formatter(this: Highcharts.TooltipFormatterContextObject): string {
  const dateFormatter = (date?: string | number) => {
    if (typeof date === "string") return dayjs(date).format(DATE_ISO_US);
    return dayjs(date).format(DATE_ISO_US);
  };

  const formatValue = (pointV?: number | null) => {
    const value = (pointV || 0).toFixed(4);
    return value.slice(0, value.length - 2);
  };

  return (
    this.points?.reduce(function (str, point, idx) {
      return `${str}<p style="margin:0; margin-top: ${idx > 0 ? 0 : 8}px; display: flex; justify-content: space-between; width: 100%; column-gap: 16px;"><span style="color: ${point.series.color}; font-weight: bold;">${point.series.name}: </span><span>${formatValue(point.y)}</span></p>`;
    }, `<p style="padding-bottom: 4px; border-bottom: 1px solid silver; font-weight: bold">${dateFormatter(
      this.points?.[0]?.key
    )}</p>`) || ""
  );
}

interface IOptions {
  styles: { [key: string]: string };
  floatDigits?: number;
  extraData: { delta: IYoYData | null; yoy: IYoYData | null };
}

export const extraTooltipFormatter = (options: IOptions) => {
  const { styles, floatDigits = FLOAT_DIGITS, extraData } = options;

  return function formatter(this: Highcharts.TooltipFormatterContextObject): string {
    const dateFormatter = (date?: string | number) => {
      const milliseconds = typeof date === "number" ? date : Date.parse(date || "");
      return dayjs(milliseconds).format(DATE_ISO_US);
    };

    const inner =
      this.points?.reduce(
        (str, point, idx) => {
          const { extraValue, paramName } = getExtraPointData(
            extraData,
            point.series.name,
            point.key
          );

          const value = extraValue
            ? `<span class="${styles.yoyLabel}"> / ${paramName}: <span>${extraValue.toFixed(
                floatDigits
              )}</span>%</span>`
            : "";

          return `${str}
            <p class="${styles.chartTooltip}" margin-top: ${idx > 0 ? 0 : 8}px;">
              <span style="color: ${point.series.color}; font-weight: bold;">
              ${point.series.name}:
              </span>
              <span>${point.y?.toFixed(2)}</span>
                ${value}
            </p>
        `;
        },
        `
      <p class="${styles.chartTooltip}">
        ${dateFormatter(this.points?.[0]?.key)}
      </p>`
      ) || "";

    return `<div class="${styles.tooltipContainer}">${inner}</div>`;
  };
};

export const extraLabelsFormatter = (extraLabelsData: IExpectedReturn[] | null, name: string) => {
  return function formatter(this: Highcharts.TooltipFormatterContextObject): string | false {
    const thisPointItem = this.points ? this.points[0].x : null;
    const pointData =
      (extraLabelsData && extraLabelsData.find((i) => i.date === thisPointItem)) || null;

    const additionalValues = pointData
      ? Object.entries(pointData).reduce((result, [key, value]) => {
          const isShowValue = pointData.show === 1 && replaceFields[key]?.visible;
          if (!["date", "Value", "show", "CM"].includes(key) && isShowValue && value !== null)
            return `${result}
      <p style="margin:0; margin-top: 0px; display: flex; justify-content: space-between; width: 100%; column-gap: 16px;"><span style="color: var(--muted-text); font-weight: bold;">
        ${replaceFields[key].replace}: </span><span>${value}</span>
      </p>`;
          return result;
        }, "")
      : "";

    const defaultString = `<p style="padding-bottom: 4px; border-bottom: 1px solid silver; font-weight: bold">
        ${dayjs(this.points?.[0]?.key).format(DATE_ISO_US)}
        </p>`;

    // hiding tooltip
    if (pointData?.show === 0) return false;

    return (
      this.points?.reduce(function (str, point, idx) {
        if (point.series.name === name && pointData?.Value === null) return str;
        if (point.series.name === "CM") return str;
        return `${str}<p style="margin:0; margin-top: ${idx > 0 ? 0 : 8}px; display: flex; justify-content: space-between; width: 100%; column-gap: 16px;"><span style="color: ${point.series.color}; font-weight: bold;">${point.series.name}: </span><span>${point.y?.toFixed(2)}</span></p>`;
      }, defaultString) + additionalValues || ""
    );
  };
};

export const getExtraPointData = (
  extraData: { yoy: IYoYData | null; delta: IYoYData | null },
  sireName: string,
  xValue?: string | null | number
) => {
  try {
    let yoy: number | null = null;
    const extraDataValue = extraData.delta || extraData.yoy;
    const paramName = (extraData.yoy && "YoY") || (extraData.delta && "Delta");

    if (extraDataValue && typeof xValue === "string") {
      if (sireName.toLowerCase().includes("actual") && extraDataValue?.actual)
        yoy = extraDataValue.actual[xValue];
      if (sireName.toLowerCase().includes("forecast") && extraDataValue?.forecast)
        yoy = extraDataValue.forecast[xValue];
      if (sireName.toLowerCase().includes("consensus") && extraDataValue?.consensus)
        yoy = extraDataValue.consensus[xValue];
    } else {
      return { extraValue: null, paramName: "" };
    }

    return { extraValue: yoy, paramName };
  } catch (err) {
    return { extraValue: null, paramName: "" };
  }
};

const BASE_FONT_SIZE = "14px";
const LABELS_FONT_SIZE = "10px";
const DEFAULT_NAME = "OdinUltra-Chart";
const WIDTH = 1440;

export const exportingOptions = (filename: string = DEFAULT_NAME): ExportingOptions => ({
  filename,
  sourceWidth: WIDTH,
  sourceHeight: WIDTH / 1.77,
  chartOptions: {
    chart: {
      style: {
        fontFamily: "Arial, sans-serif",
      },
    },
    xAxis: {
      labels: {
        style: {
          fontSize: LABELS_FONT_SIZE,
        },
      },
      title: {
        style: {
          fontSize: BASE_FONT_SIZE,
        },
      },
    },
    yAxis: {
      lineWidth: 1,
      title: {
        style: {
          fontSize: BASE_FONT_SIZE,
        },
      },
      labels: {
        style: {
          fontSize: LABELS_FONT_SIZE,
        },
      },
    },
    legend: {
      itemStyle: {
        fontSize: BASE_FONT_SIZE,
      },
    },
  },
});

export const createKeyDriversChartOptions = (
  chartData: IKeyDriversChatSerieData,
  type: "guidance" | null
) => {
  const colorZones = [
    {
      value: 30,
      color: "#ab0317",
    },
    {
      value: 70,
      color: "#faad14",
    },
    {
      color: "#158f01",
    },
  ];

  const filterCategories = (chartData: IKeyDriversChatSerieData) => {
    if (type === "guidance") {
      chartData.seriesData.forEach((s) => {
        (s as SeriesBarOptions).data?.forEach((d, index) => {
          if ((d as PointOptionsObject)?.y === 0 && chartData.categories)
            chartData.categories[index] = "";
        });
      });
    }
    return chartData.categories;
  };

  const sireData: Highcharts.Options = {
    title: {
      text: "",
    },
    credits: {
      enabled: false,
    },
    tooltip: {
      shared: ["bar", "column"].includes(chartData.type) ? false : true,
      valueSuffix:
        chartData.type === "pie" || chartData?.inPercentage || type === "guidance" ? "%" : "",
      valuePrefix: "",
      // formatter: ,
    },
    navigator: {
      enabled: false,
    },
    rangeSelector: {
      enabled: false,
    },
    chart: {
      type: chartData.type,
      marginTop: 10,
      style: {
        fontFamily: `'Inter', 'Arial', sans-serif`,
      },
    },
    legend: {
      enabled: false,
    },
    exporting: {
      enabled: false,
    },
    colors: CHART_COLORS,

    plotOptions: {
      series: {
        stacking: chartData?.inPercentage ? "percent" : "normal",
        dataLabels: {
          enabled: ["pie"].includes(chartData.type),
          useHTML: true,
          style: {
            color: "#000000",
          },
        },
        animation: {
          duration: 300,
        },
        zones: type === "guidance" ? colorZones : undefined,
      },
      bar: {
        tooltip: {
          pointFormat: "<b>{point.y}</b>",
        },
        borderRadius: 4,
      },
      pie: {
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: true,
          format: "<b>{point.name}</b>: {point.percentage:.2f} %",
        },
        size: 200,
        tooltip: {
          pointFormat: "{series.name}: <b>{point.percentage:.2f}%</b>",
        },
      },
    },

    accessibility:
      chartData.type === "pie" || chartData?.inPercentage
        ? {
            point: {
              valueSuffix: "%",
            },
          }
        : undefined,

    yAxis: {
      title: { text: "" },
      max: type === "guidance" ? 100 : null,
      labels: type === "guidance" ? { format: "{text}%" } : {},
    },
    scrollbar: {
      enabled: false,
    },
    xAxis: {
      type: "datetime",
      categories: filterCategories(chartData),
      labels: {
        style: {
          width: type === "guidance" ? 120 : undefined,
        },
      },
    },
    series: chartData.seriesData.map((serie) => ({
      ...serie,
      name: "Revenue",
    })),
  };

  return sireData;
};
