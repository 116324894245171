import { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import { ItemType } from "@/components";
import { ROUTES, indicatorsType, operatorsList } from "@/constants";
import { useAppDispatch, useAppSelector } from "@/hooks";
import {
  useGetCustomIndicatorsListQuery,
  useGetIndicatorsListQuery,
} from "@/services/apiQuery/strategies";
import { profileInfo } from "@/store/profile";
import { selectMyStrategies } from "@/store/strategies/my_strategies";
import {
  addOperator,
  clearAll,
  deleteOperator,
  setIndicator,
  strategiesState,
  updateOperator,
  setAdvancedFilter,
  setNumFieldValue,
} from "@/store/strategies/strategies";
import { IIndicatorType, IndicatorsType, OperatorType } from "@/types";
import { getTickerName, instrumentTypeMapToDeEqEtBa } from "@/utils";

export type FieldType = "category" | "indicator";

const indicatorTypeMapper: Record<string, string> = {
  Technical: "technical",
  "Historical Fundamental": "fundamental",
  "Forecasted Fundamental": "forecast",
  Economic: "economic",
  Valuation: "valuation",
  Event: "event",
  Custom: "technical",
};

export const useConstructor = (isSaving: boolean) => {
  const dispatch = useAppDispatch();
  const {
    newStartegyForm: { tradingInstrument, ticker, conditions, activeCondition },
  } = useAppSelector(strategiesState);
  const { profile } = useAppSelector(profileInfo);
  const { selectedTickersList } = useAppSelector(selectMyStrategies);

  const constructorList = conditions[activeCondition]?.list || [];
  const indicatorType = conditions[activeCondition].category || null;
  const instrumentType = instrumentTypeMapToDeEqEtBa(tradingInstrument.type) || "equities";
  const { basket } = tradingInstrument.equityBaskets;
  const queryTicker = getTickerName({
    selectedTickersList,
    basket,
    ticker,
    optionTicker: tradingInstrument.options.ticker,
    instrument: tradingInstrument.type,
  });

  const { data: indicators, isFetching: isIndicatorsLoading } = useGetIndicatorsListQuery({
    name: (indicatorType && indicatorTypeMapper[indicatorType]) || "technical",
    instrument: instrumentType,
    tickers: queryTicker,
  });

  const { data: customIndicatorsList, isFetching: isCustomIndicatorsLoading } =
    useGetCustomIndicatorsListQuery(indicatorType as IndicatorsType);

  const unitedIndicatorsList = useMemo(() => {
    return [
      ...(customIndicatorsList?.map<IIndicatorType>((ind) => ({
        indicator: ind.name,
        available: 1,
        id: ind.id,
        description: ind.description || undefined,
      })) || []),
      ...(indicators || []),
    ];
  }, [indicators, customIndicatorsList]);

  const constructorListLen = constructorList.length;
  const [isChecked, setIsChecked] = useState(false);
  const navigate = useNavigate();

  const selectIndicatorHandler = useCallback((fieldName: FieldType, item: ItemType<string>) => {
    dispatch(setIndicator({ item: item.value, fieldName }));
  }, []);

  const selectOperatorHandler = useCallback(
    (item: string, operator?: OperatorType) => {
      const isCustomIndicator = customIndicatorsList?.map((ind) => ind.name).includes(item);
      if (operator) {
        const isOperator = operatorsList.includes(item);
        dispatch(
          updateOperator({
            ...operator,
            value: item,
            isOperator,
            isInserted: isChecked,
            userID: undefined,
          })
        );
      } else {
        const newOperator = {
          value: item,
          isOperator: true,
          index: constructorListLen,
          isNumber: item === "Number",
          numValue: "",
          userID: isCustomIndicator && profile?.id ? profile.id : undefined,
        };
        dispatch(addOperator(newOperator));
      }
    },
    [constructorListLen, isChecked, customIndicatorsList]
  );

  const clearHandle = useCallback(() => {
    dispatch(clearAll());
    dispatch(setAdvancedFilter(null));
  }, []);

  const deleteHandler = useCallback((operator: OperatorType) => {
    dispatch(deleteOperator(operator));
  }, []);

  const clearFilterHandler = () => {
    dispatch(setAdvancedFilter(null));
  };

  const addIndicatorHandler = useCallback(() => {
    const category = conditions[activeCondition].category;
    const indicatorName = conditions[activeCondition].indicator;

    const isCustomIndicator = indicatorName
      ? customIndicatorsList?.map((ind) => ind.name).includes(indicatorName)
      : null;

    const indicator = unitedIndicatorsList?.find((i) => i.indicator === indicatorName);

    if (category && indicator && indicatorName) {
      dispatch(
        addOperator({
          category,
          value: indicatorName,
          index: constructorListLen,
          isOperator: false,
          info: indicator.description,
          userID: isCustomIndicator && profile?.id ? profile.id : undefined,
        })
      );
    }
  }, [conditions, activeCondition, constructorListLen, customIndicatorsList, unitedIndicatorsList]);

  const addMetricFilter = (filter: string) => {
    dispatch(setAdvancedFilter(filter));
  };

  const changeValueHandler = (value: string, index: number) => {
    dispatch(setNumFieldValue({ value, index }));
  };

  const customIndicatorEditHandler = () => {
    if (selectedIndicator) {
      const list = unitedIndicatorsList;
      const foundIndicator = list?.find(
        (indicator) => indicator.id && indicator.indicator === selectedIndicator.value
      );

      if (foundIndicator) {
        const name = encodeURIComponent(selectedIndicator.value);
        navigate(`${ROUTES.strategiesCustomIndicator.path}/${foundIndicator.id}?name=${name}`);
      }
    }
  };

  const createNewCustomIndicatorHandler = () => {
    navigate(ROUTES.strategiesCustomIndicator.path);
  };

  const selectedIndicatorType = useMemo(() => {
    const category = conditions[activeCondition].category || "";

    return category ? indicatorsType.find((i) => i.value === category) || null : null;
  }, [activeCondition, conditions, indicatorsType]);

  const selectedIndicator = useMemo(() => {
    const indicator = conditions[activeCondition].indicator;
    return indicator
      ? {
          key: indicator.toLowerCase(),
          value: indicator,
        }
      : null;
  }, [activeCondition, conditions]);

  const indicatorsList = useMemo(() => {
    if (selectedIndicatorType && selectedIndicatorType?.key === "custom") {
      return (
        unitedIndicatorsList
          ?.filter((ind) => Object.hasOwn(ind, "id"))
          .map((item, key) => ({
            key: `cus-${key.toString()}`,
            value: item.indicator || "Not available",
            info: item.description,
            title: item.indicator || undefined,
          })) || []
      );
    } else {
      return (
        unitedIndicatorsList
          ?.filter((ind) => !Object.hasOwn(ind, "id"))
          .map((item, key) => ({
            key: `def-${key.toString()}`,
            value: item.indicator || "Not available",
            info: item.description,
            title: item.indicator || undefined,
          })) || []
      );
    }
  }, [unitedIndicatorsList, selectedIndicatorType]);

  const status = conditions[activeCondition].formula;

  const error = conditions[activeCondition].error;

  const isIndicatorLoading = isIndicatorsLoading || isCustomIndicatorsLoading;

  const isBasketFormType = tradingInstrument.type === "baskets";

  const { advancedFilter } = tradingInstrument.equityBaskets;

  const metricsList = tradingInstrument.equityBaskets.metricsList;

  const disabled =
    (tradingInstrument.type === "baskets" &&
      tradingInstrument.equityBaskets.tickers?.length === 0 &&
      tradingInstrument.equityBaskets.basket === "custom-basket") ||
    (tradingInstrument.type === "stocks/etfs" && !(ticker.equities || ticker.etfs)) ||
    (tradingInstrument.type === "options" && !tradingInstrument.options.ticker) ||
    (tradingInstrument.type === "cryptos" && !ticker.cryptos);

  const canEditUserIndicator = useMemo(() => {
    return (
      !!customIndicatorsList?.find((ind) => ind.name === conditions[activeCondition].indicator) &&
      selectedIndicatorType?.key === "custom"
    );
  }, [activeCondition, indicatorType, customIndicatorsList, conditions, selectedIndicatorType]);

  return {
    isChecked,
    metricsList,
    isBasketFormType,
    isIndicatorLoading,
    indicatorsList,
    constructorList,
    disabled,

    setIsChecked,
    clearHandle,
    deleteHandler,
    addMetricFilter,
    clearFilterHandler,
    addIndicatorHandler,
    selectOperatorHandler,
    selectIndicatorHandler,
    changeValueHandler,
    customIndicatorEditHandler,
    createNewCustomIndicatorHandler,

    canEditUserIndicator,
    advancedFilterStatus: isBasketFormType ? advancedFilter : null,
    selectedIndicatorType,
    selectedIndicator,
    status,
    error,
  };
};
