export interface ISliderCard {
  title: string;
  quarter: string;
  value: string | null;
}

export enum CardEnum {
  SP500 = "S&P 500",
}

export type CardData = Record<string, string | null>;

export type CardDataResponse = {
  Sector: CardEnum | null;
  Reported: string | null;
  LastUpdated: string | null;
  Quarter: string | null;
} & Record<string, number | null>;
