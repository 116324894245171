import data from "@emoji-mart/data";
import { init } from "emoji-mart";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter as RootRouter } from "react-router-dom";

import { initErrorsLogging } from "@/constants";
import { store } from "@/store";

import { ErrorBoundaries } from "./features";
import { statisticService } from "./services";

// import a logger wrapper
import "./logger-wrapper";

// import global styles
import "@/styles/index.css";

// initialize emoji
init({ data });
// initialize Sentry Error Logger
initErrorsLogging();

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <RootRouter>
      <ErrorBoundaries />
    </RootRouter>
  </Provider>
  // </React.StrictMode>
);
