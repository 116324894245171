import dayjs from "dayjs";

import { CardData, CardDataResponse, CardEnum, ISliderCard } from "@/types/slider";

import { mainApi } from "./main";

export const earningsStatusApi = mainApi.injectEndpoints({
  endpoints: (builder) => ({
    getEarningsStatus: builder.query({
      query: (arg: CardEnum | null) => ({
        url: `/forecast/earnings-stats-qtd`,
        method: "get",
      }),
      transformResponse: (
        response: { result: CardDataResponse[] | null },
        meta,
        arg
      ): ISliderCard[] => {
        const data =
          response.result
            ?.filter((item) => (arg ? item.Sector === arg : false))
            .map(
              (item): CardData =>
                Object.keys(item).reduce((acc, key) => {
                  if (!["lastupdated", "sector"].includes(key.toLowerCase()))
                    acc[key] = item[key]?.toString() || null;
                  return acc;
                }, {} as Record<string, string | null>)
            )[0] || null;

        const quarter = response.result?.at(0)?.Quarter;

        if (data)
          return Object.keys(data).map((key) => ({
            value: data[key]?.toString() || null,
            title: key,
            quarter: quarter ? quarter : "N/A",
          }));

        return [];
      },
    }),
  }),
});

export const { useGetEarningsStatusQuery } = earningsStatusApi;
