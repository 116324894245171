import { FC, Fragment } from "react";

import { Loader, TooltipWrapper } from "@/components";
import { ISliderCard } from "@/types/slider";

import styles from "./CardItem.module.scss";

const keyMapper: Record<
  string,
  { title: string; isColored: boolean; postfix: string | null; helpText: string | null }
> = {
  Reported: {
    title: "S&P 500 Reported",
    isColored: false,
    postfix: null,
    helpText: `Total number of S&P 500 reported earnings this quarter`,
  },
  "Beat Revenue (%)": {
    title: "Revenue Beat Rate",
    isColored: true,
    postfix: "%",
    helpText: `Percentage of companies that reported revenue beat this quarter`,
  },
  "Beat EPS (%)": {
    title: "EPS Beat Rate",
    isColored: true,
    postfix: "%",
    helpText: `Percentage of companies that reported EPS beat this quarter`,
  },
  "1-Day Stock Up (%)": {
    title: "1-Day Stock Up",
    isColored: true,
    postfix: "%",
    helpText: `Percentage of companies’ stocks that moved up one trading day after report (close-to-close)`,
  },
  "Ave. 1-Day Stock Move (%)": {
    title: "Ave 1-Day Stock Move",
    isColored: false,
    postfix: "%",
    helpText: `Absolute move of companies’ stocks one trading day after report (close-to-close)`,
  },
};

const getColor = (
  isColored: boolean,
  value?: string | null
): { color: string; isInverted: boolean } => {
  let isInverted = false;
  let color = "var(--default-bg-color)";
  if (isColored && value && Number(value) >= 55) {
    color = "var(--success)";
    isInverted = true;
  }
  if (isColored && value && Number(value) > 45 && Number(value) < 55) {
    color = "var(--warning)";
    isInverted = true;
  }
  if (isColored && value && Number(value) <= 45) {
    color = "var(--error)";
    isInverted = true;
  }
  return {
    color,
    isInverted,
  };
};

interface IProps {
  card: ISliderCard | null;
}

const hasValue = (value?: string | null) => Boolean(value !== null && value !== undefined);

export const CardItem: FC<IProps> = ({ card }) => {
  const { title, quarter, value } = card || {};
  const postfix = (title && keyMapper[title].postfix) || "";
  const cardStyle = getColor(!!(title && keyMapper[title].isColored), value);
  const formatedTitle = title ? keyMapper[title].title || "N/A" : "N/A";
  const helpText = (title && keyMapper[title].helpText) || null;

  return (
    <TooltipWrapper text={helpText} title={formatedTitle}>
      <div className={styles.container}>
        {card ? (
          <Fragment>
            <p className={styles.title}>{formatedTitle}</p>
            <div className={styles.cardWrapper} style={{ backgroundColor: cardStyle.color }}>
              <p className={styles.left}>{quarter}</p>
              <p className={`${styles.right} ${cardStyle.isInverted ? styles.inverted : ""}`}>
                {hasValue(value) ? value : "-"}
                {hasValue(value) ? postfix : ""}
              </p>
            </div>
          </Fragment>
        ) : (
          <div className={styles.empty}>
            <Loader />
          </div>
        )}
      </div>
    </TooltipWrapper>
  );
};
