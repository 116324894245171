import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { statisticService } from "@/services";

const excludedPages = ["/insights"];

export const usePiwikAnalytics = () => {
  const location = useLocation();

  useEffect(() => {
    if (!excludedPages.includes(location.pathname)) {
      statisticService.initPiwik();
    }
  }, [location.pathname]);
};
