import dayjs from "dayjs";
import React, { FC, Fragment } from "react";
import { Link } from "react-router-dom";

import { ROUTES } from "@/constants";

import styles from "./FooterNavigation.module.scss";

interface IProps {
  children?: React.ReactNode;
  fullWidth?: boolean;
}

export const FooterNavigation: FC<IProps> = ({ children, fullWidth }) => {
  return (
    <footer className={styles.container} data-full-width={fullWidth}>
      <div className={styles.inner}>
        {children ? (
          children
        ) : (
          <Fragment>
            <span></span>
            <div className={styles.footerText}>
              <span className={styles.copyright}>
                © {dayjs().format("YYYY")} Product of Big Data Federation, Inc.
              </span>
              <span className={styles.footerNav}>
                <Link to={ROUTES.disclosures.path}>Disclosures</Link> |{" "}
                <Link to={ROUTES.privacyPolicy.path}>Privacy Policy</Link>
              </span>
            </div>
          </Fragment>
        )}
      </div>
    </footer>
  );
};
